@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

/* Placeholder (at the top) */
.ProseMirror p.is-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  opacity: 0.5;
  pointer-events: none;
  height: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

button:focus {
  outline: none;
}

@layer utilities {
  @variants responsive {
    /* -------- PAGE -------- */
    .app {
      @apply font-mont bg-pageBG text-pageFG min-h-screen;
    }

    .page {
      @apply grid min-h-screen;
      grid-template: 'header' auto 'body' 1fr 'horzNav' auto / 1fr;
    }

    .body-grid3 {
      @apply grid h-full ;
      grid:
        'left content right' 1fr 'footer footer footer' auto / 1fr min(
          100vw,
          500px
        )
        1fr;
    }

    .body_grid3-wide-sm {
      grid-template-columns: 1fr 100vw 1fr;
    }
    .body_grid3-wide-vertNav {
      grid-template-columns: 1fr 75vw 1fr;
    }

    .body-grid3-wide {
      @apply grid h-full body_grid3-wide-sm vertNav:body_grid3-wide-vertNav ;
      grid-template-rows: 1fr auto;
      grid-template-areas: 'left content right' 'footer footer footer';
    }

    .body-grid2 {
      @apply grid;
      grid-template-rows: 1fr;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-areas: 'left content';
    }

    /* The min-h-0 helps with creating full-height nested grid. use h-full for
    the nested grid. From:https://stackoverflow.com/questions/48211305  */
    .body-grid-content-no-pad {
      @apply relative min-h-0 z-0;
      grid-area: content;
    }
    .body-grid-content-no-vert-pad {
      @apply body-grid-content-no-pad px-2;
    }
    .body-grid-content-vert-pad {
      @apply py-2 md:py-3;
    }
    .body-grid-content {
      @apply body-grid-content-no-vert-pad body-grid-content-vert-pad;
    }

    .body-grid-right {
      grid-area: right;
    }

    .body-grid-footer {
      @apply z-40;
      grid-area: footer;
    }
    .body-grid-footer-positioned {
      @apply body-grid-footer sticky bottom-[4.92rem] vertNav:bottom-0;
    }

    .modal-base {
      @apply fixed z-50 inset-0 overflow-y-auto flex items-center justify-center;
    }
    .modal-overlay {
      @apply fixed z-0 inset-0 bg-black opacity-60;
    }
    /* -------- BUTTON -------- */
    .a-btn {
      @apply inline-block text-center;
    }

    .btn-outline {
      @apply rounded-full;
      box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 1);
    }

    .btn-dark {
      @apply rounded-full text-buttonDarkFG  bg-buttonDarkBG;
    }

    .btn-dark-warnFG {
      @apply rounded-full text-errorFG  bg-buttonDarkBG;
    }

    .btn-dark-warnBG {
      @apply rounded-full bg-errorBlockBG  text-errorBlockFG;
    }

    .btn-light {
      @apply rounded-full text-buttonLightFG  bg-buttonLightBG;
    }

    .btn-dark:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .btn-sm {
      @apply pl-4 pr-4;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }

    .sm2 {
      font-size: 0.9rem;
      line-height: 1.05rem;
    }

    .btn-md {
      @apply pt-1 pb-1 pl-4 pr-4 text-lg;
    }

    .btn-lg {
      @apply pt-2 pb-2 pl-6 pr-6 text-lg;
    }

    /* ------- MODAL ------------ */
    .modal-title {
      @apply text-lg font-bold mb-4;
    }

    .modal-p {
      @apply font-karla text-base mt-2;
    }

    .modal-p-top {
      @apply modal-p mt-0;
    }

    /* -------- FORM -------- */
    .form-title {
      @apply text-3xl font-bold mb-8;
    }

    .form-title-sm {
      @apply text-xl font-bold mb-8;
    }

    .form-p {
      @apply font-karla text-lg mt-3;
    }

    .form-p-top {
      @apply font-karla text-lg  mt-0;
    }

    .btn-form-back {
      @apply text-2xl pt-2 pb-2 pl-0 pr-10 text-iconLightFG;
    }

    .btn-form-next {
      @apply btn-lg btn-dark;
    }

    .checkbox-md {
      flex-grow: 0;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }

    .sectionLight {
      @apply bg-sectionLightBG text-sectionLightFG;
    }

    .sectionDark {
      @apply bg-sectionDarkBG text-sectionDarkFG;
    }

    .sectionDarkAlt {
      @apply bg-sectionDarkAltBG text-sectionDarkAltFG;
    }

    /* -------- MISC -------- */

    .overlayed {
      @apply opacity-30 pointer-events-none;
    }

    .standard-input-no-border {
      @apply focus:outline-none placeholder-placeholderFG bg-[rgba(0,0,0,0)];
    }

    .standard-input {
      @apply standard-input-no-border border-b-2 border-inputBorder;
    }

    .outline-input-no-border {
      @apply focus:outline-none placeholder-placeholderFG rounded-md bg-[rgba(0,0,0,0)];
    }

    .outline-input {
      @apply outline-input-no-border border border-inputBorder;
    }

    .checkout-selected {
      @apply font-semibold;
    }

    .checkout-not-available {
      @apply text-checkoutNotAvailableFG;
    }

    .lighten {
      @apply opacity-70;
    }

    .lighten-2 {
      @apply opacity-50;
    }

    .text-2xs-tight {
      font-size: 0.65rem;
      line-height: 0.65rem;
    }

    .text-xs-tight {
      font-size: 0.75rem;
      line-height: 0.75rem;
    }

    .text-sm-tight {
      font-size: 0.875rem;
      line-height: 0.875rem;
    }

    .text-base-tight {
      font-size: 1rem;
      line-height: 1rem;
    }

    .text-base-tight2 {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    .text-lg-tight {
      font-size: 1.125rem /* 18px */;
      line-height: 1.125rem;
    }
    .text-xl-tight {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    .text-2xl-tight {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

  }
}

select:-webkit-autofill.on-page-autofill,
select:-webkit-autofill:hover.on-page-autofill,
select:-webkit-autofill:focus.on-page-autofill,
select:-webkit-autofill:active.on-page-autofill,
input:-webkit-autofill.on-page-autofill,
input:-webkit-autofill:hover.on-page-autofill,
input:-webkit-autofill:focus.on-page-autofill,
input:-webkit-autofill:active.on-page-autofill {
  -webkit-box-shadow: 0 0 0 50px rgb(244, 244, 242) inset !important;
  -webkit-text-fill-color: blueviolet !important;
}

select:-webkit-autofill::first-line,
input:-webkit-autofill::first-line {
  font-family: Montserrat;
  font-size: 16px;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

/* Removes the ugly blue highlighting when clicking a button on android chrome
https://stackoverflow.com/questions/21003535*/
* {
  -webkit-tap-highlight-color: transparent;
}
